<template lang="pug">
  div Zamówienia
    app-table-store(store-namespace="ordersList")
      template(#filters="props")
        .columns
          .column.is-4
            app-table-filter-text(
              :label="$t('order_list.filter_number')"
              field="number"
              v-bind="props"
              :time="1000"
              :value-changer-to-filter="numberFlatter"
              :value-changer-to-input="numberCreator"
            )
          .column.is-4
            app-table-filter-text(
              :label="$t('order_list.filter_receiver')"
              field="search"
              v-bind="props"
            )
          .column.is-6
            table.table.is-hoverable.is-bordered.small-text.is-narrow
              tr
                td {{ $t('order_list.sum_purchase_gross_value') }}
              tr
                td {{ formatPriceValue(formatPrice(sumPurchaseGrossValue(props.list))) }}

      template(#headers="props")
        th.has-max-width-20
          b-checkbox(@input="checkAll($event, props.list)" :value="selectedOrders.length === props.list.length")
        app-table-head(v-bind="props" :label="$t('order_list.header_number')")
        app-table-head(v-bind="props" :label="$t('name')")
        app-table-head(v-bind="props" :label="$t('order_list.header_order')")
        app-table-head(v-bind="props" :label="$t('order_list.header_product')")
        app-table-head(v-bind="props" :label="$t('order_list.header_receiver')")
        app-table-head(v-bind="props" :label="$t('order_list.header_status')")
        app-table-head(v-bind="props" :label="$t('order_list.header_operations')")
        app-table-head(v-bind="props" :label="$t('order_list.header_invoice')")
        app-table-head(v-bind="props" :label="$t('order_list.header_gross_price')")

      template(#default="{ row, perPage, total }")
        td.has-max-width-20
          b-checkbox(v-model="selectedOrders" :native-value="row.id")
        td {{ orderNumber(row.number) }}
        td {{ row.name }}
        td {{ getOrderDate(row) }}
        td {{ getOrderCategory(row) }}
        td {{ getCustomerInfo(row) }}
        td {{ getStatusInfo(row) }} {{row.isSettled ? ' (Rozliczone)' : ''}}
        td
          b-button(v-if="row.productionStatus === 'end' && row.shipmentDate != null " size="is-small" type="is-info" @click.prevent="makeReclamation(row.id)") {{ $t('make_reclamation') }}
          b-button(size="is-small" type="is-primary" @click="showOrder(row)")
            b-icon(size="is-small" icon="eye")
        td
          b-button(size="is-small" :disabled="row.orderInvoice === null" @click="downloadInvoice(row.id, row.orderInvoice.invoiceNumber)") Pobierz
        td(v-if="row.customPurchaseGrossValue === null") {{ formatPriceValue(formatPrice(row.totalPurchaseGrossValue)) }}
        td(v-else) {{ formatPriceValue(formatPrice(row.customPurchaseGrossValue)) }}

        td
          .buttons.has-addons
            b-button(size="is-small" @click="printOrder(row, 'sales')") Sprzedaż
            b-button(size="is-small" @click="printOrder(row, 'purchase' )") Zakup
            b-button(size="is-small" @click="copyAsValuation(row.id, row.category)") Kopiuj jako wycenę
    order-preview-global(:active="isOrderPreviewActive" :order-id="orderId" :price-type="priceType" @close="isOrderPreviewActive=false")
    order-print(:active="isOrderPrintActive" :order-id="orderId" :price-type="priceType" @close="isOrderPrintActive=false")
</template>
<script>
import {mapGetters} from "vuex";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import orderNumberFlatter from "@/helpers/orderNumberFlatter";
import t from "@/i18n";
import moment from "moment";
import OrderPreviewGlobal from "../../common/components/OrderPreviewGlobal";
import OrderPrint from "../../common/components/OrderPrint";
import priceFormatter from "../../../helpers/priceFormatter";
import {
  DETAIL,
  EXTERNAL_SHUTTER,
  FABRIC_SHADE,
  FABRIC_SHADE_DAY_NIGHT, FRAME_MOSQUITO_NET,
  PLEAT, PLEAT_MOSQUITO_NET, ROLL_MOSQUITO_NET
} from "../../../helpers/orderCategoryConstants";

export default {
  components: {OrderPreviewGlobal, OrderPrint},
  data() {
    return {
      selectedOrders: [],
      isOrderPreviewActive: false,
      isOrderPrintActive: false,
      orderId: null,
      priceType: null
    };
  },
  computed: {
    ...mapGetters("auth", ["companyTypeForUrlProp"]),
    ...mapGetters("common", ["formatPrice"]),
  },
  methods: {
    sumPurchaseGrossValue(list) {
      const sum = list.filter(a => this.selectedOrders.some(b => a.id === b));

      return sum.reduce((it, object) => {
        return it + (object.customPurchaseGrossValue !== null
            ? object.customPurchaseGrossValue : object.totalPurchaseGrossValue);
      }, 0);

    },

    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getOrderDate(row) {
      return row.orderDate
          ? moment(row.orderDate, "DD-MM-YYYY hh:mm:ss").format(
              "YYYY-MM-DD hh:mm:ss"
          )
          : "";
    },
    isCompany(row) {
      return row.company !== null && row.company !== undefined;
    },
    getStatusInfo(row) {
      const company = row.company !== null && row.company !== undefined ? row.company : row.subCompany;
      if (company && company?.hasShipmentToSubCompany || this.isCompany(row)) {

        //data odebrania, ustawiana po zatwierdzeniu w panelu logistyka
        if (row.shipmentDate && row.deliveryMethod) {
          return row.deliveryMethod.name + " " + moment(row.shipmentDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")
        }
        //zaplanowana data odbioru, wyliczana na podstawie dni odbioru danej firmy
        else if (row.receptionDate) {
          return t.t("statuses.planned_for", {date: moment(row.receptionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")});
        }
        //zaplanowana data odbioru, wyliczana na podstawie dni odbioru danej firmy
        else if (row.productionDate) {
          if (company.receptionDays === undefined || company.receptionDays.length === 0) return t.t("statuses.empty_reception_days", {date: moment(row.productionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")});
          // Preferowane dni odbioru
          const preferredDaysOfWeek = new Set(company.receptionDays);
          const productionDate = moment(row.productionDate, "DD-MM-YYYY hh:mm:ss")
          let receptionDate = productionDate.clone().add(1, 'd'); // dodanie jednego dnia, ponieważ musi być to już kolejny dzień tygodnia

          // Szukanie najbliższego preferowanego dnia odbioru
          while (!preferredDaysOfWeek.has(receptionDate.format('ddd').toLowerCase())) {
            receptionDate = receptionDate.add(1, 'd');
          }

          return t.t("statuses.planned_for", {date: moment(receptionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")});
        }

        return t.t("statuses." + row.status);
      } else {
        if (row.productionStatus === "end") {
          return t.t("statuses.production_end");
        }
        return t.t("statuses." + row.status);
      }

    },
    getCustomerInfo(order) {
      const customer = order.customer;
      if (customer) {
        if ("person" === customer.type) {
          const lastName = customer.lastName;
          const city = customer.city;
          return `${lastName} (${city})`;
        } else if ("company" === customer.type) {
          const companyName = customer.companyName;
          const taxId = customer.taxId;
          return `${companyName} (${taxId})`;
        }
      }
      return t.t("undefined");
    },
    numberFlatter(value) {
      return orderNumberFlatter(value);
    },
    numberCreator(value) {
      return orderNumberCreator(value);
    },
    orderNumber(number) {
      return orderNumberCreator(number);
    },
    checkAll(event, list) {
      if (event) {
        this.selectedOrders = list.map(item => item.id);
      } else {
        this.selectedOrders = [];
      }
    },
    makeReclamation(id) {
      this.$router.push({
        name: "order_reclamations_create",
        params: {orderId: id}
      });
    },
    showOrder(row, priceType = null) {
      this.orderId = row.id;
      this.priceType = priceType;
      this.isOrderPreviewActive = true;
    },
    printOrder(row, priceType = null) {
      this.orderId = row.id;
      this.priceType = priceType;
      this.isOrderPrintActive = true;
    },
    /**
     * Download invoice
     * @param orderId
     * @param invoiceNumber
     */
    downloadInvoice(orderId, invoiceNumber = null) {
      this.$http.get(`/invoices/${orderId}/download`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Faktura_${invoiceNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
    },
    getOrderCategory(row) {
      let cat = "";

      if (row['@type'] === "FixedOrder") {
        cat += "(FX) ";
      }

      cat += this.$t(`product_types.${row.category}`);

      return cat;
    },
    /**
     * Copy order as valuation
     * @param id
     * @param category
     */
    copyAsValuation(id, category) {
      // check category order
      if (category === FABRIC_SHADE) {
        this.$router.push({
          name: "fabric_shades_create_order",
          params: {copyId: id}
        });
      } else if (category === FABRIC_SHADE_DAY_NIGHT) {
        this.$router.push({
          name: "fabric_shade_day_nights_create_order",
          params: {copyId: id}
        });
      } else if (category === EXTERNAL_SHUTTER) {
        this.$router.push({
          name: "external_shutters_create_order",
          params: {copyId: id}
        });
      } else if (category === PLEAT) {
        this.$router.push({
          name: "pleats_create_order",
          params: {copyId: id}
        });
      } else if (category === DETAIL) {
        this.$router.push({
          name: "details_create_order",
          params: {copyId: id}
        });
      } else if (category === FRAME_MOSQUITO_NET) {
        this.$router.push({
          name: "frame_mosquito_net_edit_order",
          params: {id: id}
        });
      } else if (category === ROLL_MOSQUITO_NET) {
        this.$router.push({
          name: "roll_mosquito_net_edit_order",
          params: {id: id}
        });
      } else if (category === PLEAT_MOSQUITO_NET) {
        this.$router.push({
          name: "pleat_mosquito_net_edit_order",
          params: {id: id}
        });
      }
    }
  },

  created() {
    this.$store.dispatch("ordersList/fetch");
  }
};
</script>
