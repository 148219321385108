<template lang="pug">
  div Zamówienia
    app-table-store(store-namespace="valuationsList" :row-color-function="highlightRetractionRow" )
      template(#filters="props")
        .columns
          .column
            app-table-filter-text(
              :label="$t('order_list.filter_receiver')"
              field="search"
              v-bind="props"
            )
          .column
            app-table-filter-text(
              :label="$t('order_list.filter_name')"
              field="name"
              v-bind="props"
            )

      template(#headers="props")
        app-table-head(v-bind="props" :label="$t('name')")
        app-table-head(v-bind="props" :label="$t('order_list.header_date')")
        app-table-head(v-bind="props" :label="$t('order_list.header_product')")
        app-table-head(v-bind="props" :label="$t('order_list.header_receiver')")

      template(#default="{ row, perPage, total }" style="{backgroundColor:red}")
        td {{ row.name || "Nie podano" }}
        td {{ getValuationDate(row) }}
        td {{ $t("product_types."+row.category) }}
        td {{ getCustomerInfo(row) }}
        td
          .buttons.has-addons
            b-button(size="is-small" @click="orderComplete(row)") Złóż zamówienie z wyceny
            b-button(size="is-small" type="is-primary" @click="showOrder(row)")
              b-icon(size="is-small" icon="eye")
            b-button(size="is-small" type="is-info" @click="editValuation(row.id, row.category)")
              b-icon(size="is-small" icon="pencil")
            app-button-remove(resource="orders" :object="row" size="is-small" @removed="fetchValuations")
      template(#footer)
        p.pb-2 {{ $t('common.legend') }} <br>
        span(style="padding-right: 45px").has-background-warning
        span.ml-3 - {{$t('restraction_valuation')}}

    order-preview-global(:active="isModalActive" :order-id="orderId" @close="isModalActive=false" :priceType="'null'")
</template>
<script>
import http from "@/http";
import notify from "@/helpers/notify";
import {mapGetters} from "vuex";
import moment from "moment";
import t from "@/i18n";
import {
  FABRIC_SHADE,
  FABRIC_SHADE_DAY_NIGHT,
  EXTERNAL_SHUTTER,
  PLEAT,
  FRAME_MOSQUITO_NET
} from "@/helpers/orderCategoryConstants"
import OrderPreviewGlobal from "../../common/components/OrderPreviewGlobal";
import {DETAIL, PLEAT_MOSQUITO_NET, ROLL_MOSQUITO_NET} from "../../../helpers/orderCategoryConstants";

export default {
  components: {OrderPreviewGlobal},
  data() {
    return {
      isModalActive: false,
      orderId: null
    };
  },
  methods: {
    getCustomerInfo(order) {
      const customer = order.customer;
      if (customer) {
        if ("person" === customer.type) {
          const lastName = customer.lastName;
          const city = customer.city;
          return `${lastName} (${city})`;
        } else if ("company" === customer.type) {
          const companyName = customer.companyName;
          const taxId = customer.taxId;
          return `${companyName} (${taxId})`;
        }
      }
      return t.t("undefined");
    },
    getValuationDate(row) {
      return row.valuationDate ? moment(row.valuationDate, "DD-MM-YYYY hh:mm:ss").format("YYYY-MM-DD") : "";
    },
    showOrder(row) {
      this.orderId = row.id;
      this.isModalActive = true;
    },
    async orderComplete(row) {
      try {
        await http.put(`orders/${row.id}/complete`, row).then(response => {
          if (response.status === 200) {
            this.fetchValuations();
            notify("Zmieniono wycenę na zamówienie");
          }
        });
      } catch {
        notify(
            "Nie udało się zmienić statusu z wyceny na zamówienie, wystąpił błąd",
            "warning"
        );
      }
    },
    fetchValuations() {
      this.$store.dispatch("valuationsList/fetch");
    },
    editValuation(id, category) {
      // check category order

      if (category === FABRIC_SHADE) {
        this.$router.push({
          name: "fabric_shades_edit_order",
          params: {id: id}
        });
      } else if (category === FABRIC_SHADE_DAY_NIGHT) {
        this.$router.push({
          name: "fabric_shade_day_nights_edit_order",
          params: {id: id}
        });
      } else if (category === EXTERNAL_SHUTTER) {
        this.$router.push({
          name: "external_shutters_edit_order",
          params: {id: id}
        });
      } else if (category === PLEAT) {
        this.$router.push({
          name: "pleats_edit_order",
          params: {id: id}
        });
      } else if (category === DETAIL) {
        this.$router.push({
          name: "details_edit_order",
          params: {id: id}
        });
      } else if (category === FRAME_MOSQUITO_NET) {
        this.$router.push({
          name: "frame_mosquito_net_edit_order",
          params: {id: id}
        });
      } else if (category === ROLL_MOSQUITO_NET) {
        this.$router.push({
          name: "roll_mosquito_net_edit_order",
          params: {id: id}
        });
      } else if ( category === PLEAT_MOSQUITO_NET) {
        this.$router.push({
          name: "pleat_mosquito_net_edit_order",
          params: {id: id}
        });
      }

    },
    highlightRetractionRow: function (row) {
      const {retraction} = row;

      if (retraction) {
        return "has-background-warning";
      }
    }
  },

  computed: {
    ...mapGetters("auth", ["companyTypeForUrlProp"])
  },
  created() {
    this.fetchValuations();
  }
};
</script>
