import router from "./../../router";
import CreateOrder from "./views/CreateOrder";
import store from "../../store";
import pleatMosquitoNet from "./store";

store.registerModule("pleatMosquitoNets", pleatMosquitoNet);
router.addRoutes([
  {
    path: "/pleat_mosquito_net/create_order/:copyId?",
    name: "pleat_mosquito_net_create_order",
    component: CreateOrder,
    props: true
  }
]);

router.addRoutes([
  {
    path: "/pleat_mosquito_net/edit_order/:id",
    name: "pleat_mosquito_net_edit_order",
    component: CreateOrder,
    props: true
  }
]);
