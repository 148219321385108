<template lang="pug">
  div
    .columns
      .column.is-10
        p.has-font-size-15 Zamówienia {{companyShortName ? ' dla ' + companyShortName : ''}}
      .column.is-1
        b-button(
          type="is-primary"
          :disabled="selectedOrders.length === 0"
          @click="isOrderForSettlementActive=true"
        ) PDF do rozliczenia
    app-table-store(store-namespace="subCompanyOrdersList" hide-operations )
      template(#filters="props")
        .columns
          .column.is-2
            app-table-filter-text(
              :label="$t('order_list.filter_number')"
              field="number"
              v-bind="props"
              :time="1000"
              :value-changer-to-filter="numberFlatter"
              :value-changer-to-input="numberCreator"
            )
          .column.is-3
            app-table-filter-text(
              :label="$t('order_list.filter_name')"
              field="name"
              v-bind="props"
            )
          .column.is-1
            app-table-filter-select(
              :label="$t('order_list.filter_status')"
              field="in"
              v-bind="props"
            )
              option(v-for="{label, value} in statusOptions" :value="value") {{ label }}
          .column.is-1
            app-table-filter-select(
              :label="$t('order_list.filter_settled')"
              field="isSettled"
              placeholder="Rozliczone"
              v-bind="props"
            )
              option(v-for="{label, value} in booleanOptions" :value="value") {{ label }}
          .column
            b-field(label="Data od")
              DatePicker(v-model="beginReportDate" type="day" value-type="format" format="YYYY-MM-DD" )
          .column
            b-field(label="Data do")
              DatePicker(v-model="endReportDate" type="day" value-type="format" format="YYYY-MM-DD")

      template(#headers="props")
        app-table-head(v-bind="props" :label="$t('order_list.header_number')")
        app-table-head(v-bind="props" :label="$t('name')")
        app-table-head(v-bind="props" :label="$t('order_list.header_order')")
        app-table-head(v-bind="props" :label="$t('order_list.header_product')")
        app-table-head(v-bind="props" :label="$t('order_list.header_status')")
        app-table-head(v-bind="props" :label="$t('order_list.header_preview')")
        app-table-head(v-bind="props" :label="$t('order_list.header_net_purchase_price')" :label-tooltip="$t('order_list.header_net_purchase_company_tooltip')")
        app-table-head(v-bind="props" :label="$t('order_list.header_net_sales_price')" :label-tooltip="$t('order_list.header_net_sales_subcompany_tooltip')")
        app-table-head(v-bind="props" :label="$t('order_list.header_net_margin')")
        //app-table-head(v-bind="props" :label="$t('order_list.header_operations')")
        th.has-max-width-20
          b-checkbox(@input="checkAll($event, props.list)" :value="selectedOrders.length === props.list.filter(item => item.subCompany && item.subCompany.hasInvoicingByProducer && item.orderInvoice && !item.isSettled).length" )
            p {{$t('order_list.header_operations') }}

      template(#default="{ row, perPage, total }")
        td {{ orderNumber(row.number) }}
        td {{ row.name }}
        td {{ getOrderDate(row) }}
        td {{ getOrderCategory(row) }}
        td
          p {{ getStatusInfo(row) }}
          p(v-if="row.orderInvoice") ({{ $t('statuses.invoiced') }})
        td
          b-button(size="is-small" type="is-primary" @click="showOrder(row)")
            b-icon(size="is-small" icon="eye")
        td {{ formatPriceValue(formatPrice(row.totalNetPurchaseValueBeforeDiscount - row.companyMarginProfit))  }}
        td {{ formatPriceValue(formatPrice(row.totalNetPurchaseValueBeforeDiscount))}}
        td {{ formatPriceValue(formatPrice(row.companyMarginProfit)) }}
        td
          .buttons.has-addons
            b-checkbox(size="is-small" :value="isCheckOrder(row)" @input='checkOrder(row)' :native-value="row" :disabled="!row.subCompany.hasInvoicingByProducer || !row.orderInvoice || row.isSettled") Rozlicz
    order-preview-global(:active="isOrderPreviewActive" :order-id="orderId" :price-type="priceType" @close="isOrderPreviewActive=false")
    order-print(:active="isOrderPrintActive" :order-id="orderId" :price-type="priceType" @close="isOrderPrintActive=false")
    orders-for-settlement(:active="isOrderForSettlementActive" :orders="selectedOrders" @close="isOrderForSettlementActive=false" @refresh="refreshOrdersAndClearSelectedOrders")
</template>
<script>
import {mapGetters} from "vuex";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import orderNumberFlatter from "@/helpers/orderNumberFlatter";
import t from "@/i18n";
import moment from "moment";
import OrderPreviewGlobal from "../../common/components/OrderPreviewGlobal";
import OrderPrint from "../../common/components/OrderPrint";
import priceFormatter from "../../../helpers/priceFormatter";
import {
  DETAIL,
  EXTERNAL_SHUTTER,
  FABRIC_SHADE,
  FABRIC_SHADE_DAY_NIGHT, FRAME_MOSQUITO_NET,
  PLEAT, ROLL_MOSQUITO_NET
} from "../../../helpers/orderCategoryConstants";
import router from "../../../router";
import http from "../../../http";
import OrdersForSettlement from "../../common/components/OrdersForSettlement.vue";
import DatePicker from "vue2-datepicker";

export default {
  components: {OrdersForSettlement, OrderPreviewGlobal, OrderPrint, DatePicker},
  data() {
    return {
      selectedOrders: [],
      isOrderPreviewActive: false,
      isOrderPrintActive: false,
      isOrderForSettlementActive: false,
      orderId: null,
      priceType: null,
      companyShortName: null,
      booleanOptions: [
        {label: "Wszystkie", value: '', selected: true},
        {label: "Tak", value: "true"},
        {label: "Nie", value: "false"}
      ],
      statusOptions: [
        {label: "Wszystkie", value: "new accepted production", selected: true},
        {label: "Nowy", value: "new"},
        {label: "Przyjęte", value: "accepted"},
      ],
      beginReportDate: null,
      endReportDate: null
    };
  },
  computed: {
    ...mapGetters("auth", ["companyTypeForUrlProp"]),
    ...mapGetters("common", ["formatPrice"]),
    ...mapGetters("subCompanyOrdersList", ["list"]),
    filteredSelectedOrders: {
      get() {
        const settledOrders = this.$store.getters["subCompanyOrdersList/list"].filter(order => order.isSettled);
        return [...this.selectedOrders, ...settledOrders];
      },
      set(value) {
        this.selectedOrders = value;
      }
    }
  },
  watch: {
    beginReportDate() {
      // begin and nend shoud be in timestamp format
      const beginDateTime = moment(this.beginReportDate).format("YYYY-MM-DD HH:mm:ss");
      this.$store.dispatch("subCompanyOrdersList/filter", {
        field: 'orderDate[strictly_after]',
        value: beginDateTime
      });
    },
    endReportDate() {
      const endDateTime = moment(this.endReportDate).format("YYYY-MM-DD HH:mm:ss");

      this.$store.dispatch("subCompanyOrdersList/filter", {
        field: 'orderDate[before]',
        value: endDateTime
      });
    },
  },
  methods: {
    refreshOrdersAndClearSelectedOrders() {
      this.$store.dispatch("subCompanyOrdersList/fetch");
      this.selectedOrders = [];
    },
    isCheckOrder(row) {
      return row.isSettled || this.selectedOrders.includes(row);
    },
    checkOrder(row) {
      if (!row.isSettled) {
        if (!this.selectedOrders.includes(row)) {
          this.selectedOrders.push(row);
        } else {
          this.selectedOrders = this.selectedOrders.filter(order => order !== row);
        }
      }
    },
    async getSubCompanyName() {
      if (router.currentRoute.params.subCompany) {
        return router.currentRoute.params.subCompany.shortName
      } else {
        const r = await http.get(`/sub_companies/${router.currentRoute.query["subCompany.id"]}?properties[]=shortName`)
        this.companyShortName = r.data.shortName
      }
    },

    formatPriceValue(value) {
      return priceFormatter(value);
    },
    getOrderDate(row) {
      return row.orderDate
          ? moment(row.orderDate, "DD-MM-YYYY hh:mm:ss").format(
              "YYYY-MM-DD hh:mm:ss"
          )
          : "";
    },
    /**
     * If company has shipment to sub company, then status is calculated by shipment date and delivery method
     * If company has not shipment to sub company, then status is calculated by status
     * @param row
     * @returns {*|string}
     */
    getStatusInfo(row) {
      const company = row.company !== null ? row.company : row.subCompany;
      if (company && company?.hasShipmentToSubCompany) {
        //data odebrania, ustawiana po zatwierdzeniu w panelu logistyka
        if (row.shipmentDate && row.deliveryMethod) {
          return row.deliveryMethod.name + " " + moment(row.shipmentDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")
        }
        //zaplanowana data odbioru, wyliczana na podstawie dni odbioru danej firmy
        else if (row.receptionDate) {
          return t.t("statuses.planned_for", {date: moment(row.receptionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")});
        }
        //zaplanowana data odbioru, wyliczana na podstawie dni odbioru danej firmy
        else if (row.productionDate) {
          if (company.receptionDays === null || company.receptionDays.length === 0) return t.t("statuses.empty_reception_days", {date: moment(row.productionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")});
          if (company.receptionDays.length === 0) return t.t("statuses.empty_reception_days", {date: moment(row.productionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")});
          // Preferowane dni odbioru
          const preferredDaysOfWeek = new Set(company.receptionDays);
          const productionDate = moment(row.productionDate, "DD-MM-YYYY hh:mm:ss")
          let receptionDate = productionDate.clone().add(1, 'd'); // dodanie jednego dnia, ponieważ musi być to już kolejny dzień tygodnia

          // Szukanie najbliższego preferowanego dnia odbioru
          while (!preferredDaysOfWeek.has(receptionDate.format('ddd').toLowerCase())) {
            receptionDate = receptionDate.add(1, 'd');
          }

          return t.t("statuses.planned_for", {date: moment(receptionDate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")});
        }

        return t.t("statuses." + row.status);
      } else {
        if (row.productionStatus === "end") {
          return t.t("statuses.production_end");
        }
        return t.t("statuses." + row.status);
      }

    },
    getCustomerInfo(order) {
      const customer = order.customer;
      if (customer) {
        if ("person" === customer.type) {
          const lastName = customer.lastName;
          const city = customer.city;
          return `${lastName} (${city})`;
        } else if ("company" === customer.type) {
          const companyName = customer.companyName;
          const taxId = customer.taxId;
          return `${companyName} (${taxId})`;
        }
      }
      return t.t("undefined");
    },
    numberFlatter(value) {
      return orderNumberFlatter(value);
    },
    numberCreator(value) {
      return orderNumberCreator(value);
    },
    orderNumber(number) {
      return orderNumberCreator(number);
    },
    checkAll(event, list) {
      if (event) {
        this.selectedOrders = list.filter(item => item.subCompany?.hasInvoicingByProducer && item.orderInvoice && !item.isSettled)
      } else {
        this.selectedOrders = [];
      }
    },
    makeReclamation(id) {
      this.$router.push({
        name: "order_reclamations_create",
        params: {orderId: id}
      });
    },
    showOrder(row, priceType = null) {
      this.orderId = row.id;
      this.priceType = priceType;
      this.isOrderPreviewActive = true;
    },
    printOrder(row, priceType = null) {
      this.orderId = row.id;
      this.priceType = priceType;
      this.isOrderPrintActive = true;
    },
    getOrderCategory(row) {
      let cat = "";

      if (row['@type'] === "FixedOrder") {
        cat += "(FX) ";
      }

      cat += this.$t(`product_types.${row.category}`);

      return cat;
    },
    /**
     * Copy order as valuation
     * @param id
     * @param category
     */
    copyAsValuation(id, category) {
      // check category order
      if (category === FABRIC_SHADE) {
        this.$router.push({
          name: "fabric_shades_create_order",
          params: {copyId: id}
        });
      } else if (category === FABRIC_SHADE_DAY_NIGHT) {
        this.$router.push({
          name: "fabric_shade_day_nights_create_order",
          params: {copyId: id}
        });
      } else if (category === EXTERNAL_SHUTTER) {
        this.$router.push({
          name: "external_shutters_create_order",
          params: {copyId: id}
        });
      } else if (category === PLEAT) {
        this.$router.push({
          name: "pleats_create_order",
          params: {copyId: id}
        });
      } else if (category === DETAIL) {
        this.$router.push({
          name: "details_create_order",
          params: {copyId: id}
        });
      } else if (category === FRAME_MOSQUITO_NET) {
        this.$router.push({
          name: "frame_mosquito_net_edit_order",
          params: {id: id}
        });
      } else if (category === ROLL_MOSQUITO_NET) {
        this.$router.push({
          name: "roll_mosquito_net_edit_order",
          params: {id: id}
        });
      }
    }
  },

  created() {
    this.getSubCompanyName();
    this.$store.dispatch("subCompanyOrdersList/fetch");
  }
};
</script>
